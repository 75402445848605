import {setModalWindowsReady} from './modal';
import {setSubmitListeners} from './form'
import {showHamburger, tagGoogle, spyScrolling, getCookie } from './utilities'


// ADD EVENT LISTENERS
setModalWindowsReady();
setSubmitListeners();
showHamburger;
document.addEventListener('scroll', tagGoogle);
spyScrolling();
var source = getCookie("source") == "" ? (document.referrer) : false ;
if(source){
    var d = new Date();
    document.cookie = "source="+source+"; expires=Thu, 18 Dec "+(d.getFullYear() + 10)+" 12:00:00 UTC";
}